<template>
  <div class="container wrapper">
    <div class="d-flex text-center topTab" v-if="!$isMobile()">
      <div class="" @click="currentTab = 'job'">
        <h1
          class="text-uppercase"
          :class="currentTab === 'job' ? ' pink2--text' : ''"
        >
          Search Job Post
        </h1>
      </div>
      <div class="" @click="currentTab = 'user'">
        <h1
          class="text-uppercase"
          :class="currentTab === 'user' ? ' pink2--text' : ''"
        >
          Search User
        </h1>
      </div>
    </div>
    <div class="py-2 row topTab" v-else>
      <div class="" @click="currentTab = 'job'">
        <h2
          class="text-uppercase"
          :class="currentTab === 'job' ? ' pink2--text' : ''"
        >
          Job Post
        </h2>
      </div>
      <div class="text-right" @click="currentTab = 'user'">
        <h2
          class="text-uppercase"
          :class="currentTab === 'user' ? ' pink2--text' : ''"
        >
          User
        </h2>
      </div>
    </div>
    <AppSearchBar
      v-model="search"
      :search="getSearchResultDebounce"
      class="mt-10"
    />
    <UserContainerList
      v-if="currentTab == 'user'"
      :lists="lists"
      nameKey="name"
      :custom="{
        hasCharacteristic: true
      }"
      photoKey="logo_image"
      :searching="searching"
    />
    <template v-else>
      <v-row no-gutters justify="center" class="mb-10 pb-12">
        <v-col
          v-for="(item, index) in lists"
          :key="index"
          cols="12"
          md="12"
          class="userContainer mt-5"
        >
          <router-link
            class="text-decoration-none"
            :to="{
              name: routeName.JOB_DETAILS,
              params: {
                id: item.id
              }
            }"
          >
            <div class="d-flex" style="color: white">
              <div class="align-self-center py-3 ml-8">
                <div class="font-weight-bold">
                  {{ item.name }}
                </div>
                <div class="titleFont font-weight-light">
                  {{ item.producer }}
                </div>
              </div>
              <div
                class="text-decoration-none my-10 mx-5 align-self-center ml-auto"
              >
                <v-icon color="white" class="icon"> mdi-chevron-right </v-icon>
              </div>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </template>
    <div
      v-if="lists.length && hasNext"
      v-observe-visibility="scrolledToBottom"
    ></div>
  </div>
</template>

<script>
  import { HOME_SCREENS } from '@/api';
  import { ROUTE_NAME } from '@/constants';
  import { mapGetters } from 'vuex';
  import UserContainerList from '@/components/base/UserContainerList';

  export default {
    name: 'Search',
    components: {
      UserContainerList
    },
    data: () => ({
      me: null,
      lists: [],
      currentTab: 'job',
      search: '',
      searching: false,
      perPage: 10,
      hasNext: false,
      routeName: ROUTE_NAME
    }),

    watch: {
      currentTab() {
        this.lists = [];
        this.$scrollToTop();
        this.getSearchResult();
      }
    },
    computed: {
      ...mapGetters({
        languagesSpoken: ['constants/languageSpoken'],
        race: ['constants/race']
      })
    },

    methods: {
      getSearchResultDebounce(options) {
        this.$debounceHandler(() => {
          this.getSearchResult(options);
        });
      },
      async getSearchResult(options) {
        this.$startLoading();
        this.searching = true;
        try {
          if (this.currentTab == 'job') {
            let searchJobParams = {
              keyword: this.search || '*',
              per_page: this.perPage
            };
            if (options.location) searchJobParams.location = options.location;
            if (options.projectType)
              searchJobParams.project_type = options.projectType;
            const res = await HOME_SCREENS.searchJobPost(searchJobParams);
            this.lists = res.data.items.map((x) => {
              return {
                name: x.title,
                producer: x.producer,
                id: x.id
              };
            });
          } else {
            const res = await HOME_SCREENS.searchUser({
              keyword: this.search || '*',
              per_page: this.perPage
            });

            const pagination = res.data.pagination;
            this.hasNext = typeof pagination.next_cursor == 'string';

            this.lists = res.data.items.map((x) => {
              return {
                ...x,
                logo_image: x.headshot_file || x.company_logo
              };
            });
          }
        } catch (e) {
          //
        }
        this.searching = false;
        this.$stopLoading();
      },
      getProfileItems(item) {
        let languages = '';

        if (item.profile.languages_spoken) {
          item.profile.languages_spoken.forEach((x, index, arr) => {
            const language = this.languagesSpoken.find((y) => y.value === x);
            languages += language.description;
            if (index !== arr.length - 1) languages += ', ';
          });
        }

        return [
          {
            title: 'Height',
            value: item.profile.height
          },
          {
            title: 'Race',
            value: item.profile.race
              ? this.race.find((x) => x.value == item.profile.race).description
              : null
          },
          {
            title: 'Language',
            value: languages
          },
          {
            title: 'Skills',
            value: item.profile.skills
          }
        ];
      },
      async scrolledToBottom(isVisible) {
        if (!isVisible) {
          return;
        }

        this.perPage += 5;
        this.getSearchResult();
      }
    },
    mounted() {
      this.getSearchResult();
    }
  };
</script>

<style scoped lang="scss">
  .topTab {
    background-color: transparent !important;
    color: white;
    justify-content: space-evenly;

    & > div {
      cursor: pointer;
    }
  }
  .userContainer {
    background-color: #242424;
    border-radius: 5px;
  }

  .titleFont {
    font-size: 15px;
  }
  .titleFont2 {
    font-size: 18px;
  }
</style>
