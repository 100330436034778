import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container wrapper"},[(!_vm.$isMobile())?_c('div',{staticClass:"d-flex text-center topTab"},[_c('div',{on:{"click":function($event){_vm.currentTab = 'job'}}},[_c('h1',{staticClass:"text-uppercase",class:_vm.currentTab === 'job' ? ' pink2--text' : ''},[_vm._v(" Search Job Post ")])]),_c('div',{on:{"click":function($event){_vm.currentTab = 'user'}}},[_c('h1',{staticClass:"text-uppercase",class:_vm.currentTab === 'user' ? ' pink2--text' : ''},[_vm._v(" Search User ")])])]):_c('div',{staticClass:"py-2 row topTab"},[_c('div',{on:{"click":function($event){_vm.currentTab = 'job'}}},[_c('h2',{staticClass:"text-uppercase",class:_vm.currentTab === 'job' ? ' pink2--text' : ''},[_vm._v(" Job Post ")])]),_c('div',{staticClass:"text-right",on:{"click":function($event){_vm.currentTab = 'user'}}},[_c('h2',{staticClass:"text-uppercase",class:_vm.currentTab === 'user' ? ' pink2--text' : ''},[_vm._v(" User ")])])]),_c('AppSearchBar',{staticClass:"mt-10",attrs:{"search":_vm.getSearchResultDebounce},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.currentTab == 'user')?_c('UserContainerList',{attrs:{"lists":_vm.lists,"nameKey":"name","custom":{
      hasCharacteristic: true
    },"photoKey":"logo_image","searching":_vm.searching}}):[_c(VRow,{staticClass:"mb-10 pb-12",attrs:{"no-gutters":"","justify":"center"}},_vm._l((_vm.lists),function(item,index){return _c(VCol,{key:index,staticClass:"userContainer mt-5",attrs:{"cols":"12","md":"12"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
            name: _vm.routeName.JOB_DETAILS,
            params: {
              id: item.id
            }
          }}},[_c('div',{staticClass:"d-flex",staticStyle:{"color":"white"}},[_c('div',{staticClass:"align-self-center py-3 ml-8"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"titleFont font-weight-light"},[_vm._v(" "+_vm._s(item.producer)+" ")])]),_c('div',{staticClass:"text-decoration-none my-10 mx-5 align-self-center ml-auto"},[_c(VIcon,{staticClass:"icon",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-right ")])],1)])])],1)}),1)],(_vm.lists.length && _vm.hasNext)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.scrolledToBottom),expression:"scrolledToBottom"}]}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }